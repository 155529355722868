div.parent-container {
  display: grid;
  justify-content: center;
}

div.child-container {
  margin: 1em;
  width: 90vw;
}

.spacer {
  margin-top: 1em;
  margin-bottom: 1em;
}

div.input {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

div.horizontal-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

table,
td {
  border: 1px solid black;
}
